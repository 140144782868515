import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Logo from "../../../assets/images/logo.png";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const site = process.env.REACT_APP_SITE;
const storeId = process.env.REACT_APP_STORE_ID;

export default function LogoCenter() {
  const [mealBalance, setMealBalance] = useState();
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/customer/login/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      firstName +
      "/" +
      roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          customer.Address1.toLowerCase() === roomNumber.toLowerCase()
        ) {
          setMealBalance(customer.MealCount);
        } else {
          window.location.href = site;
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="LogoMenuHeader">
      <Toolbar>
        <Box
          id="transparent"
          sx={{
            mx: "auto",
            width: 250,
            p: 1,
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#101010" : "grey.50",
            color: (theme) =>
              theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.800" : "grey.300",
            borderRadius: 2,
            textAlign: "left",
            fontSize: "0.875rem",
            fontWeight: "700",
            marginLeft: "0",
          }}
        >
          <Typography component="div" align="left">
            Good day <b>{firstName}</b>. How can we serve you today?{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            mx: "auto",
            width: 250,
            p: 2,
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#101010" : "grey.50",
            color: (theme) =>
              theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.800" : "grey.300",
            borderRadius: 2,
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "700",
            marginLeft: "auto !important",
            marginRight: "auto !important",
          }}
        >
          <img
            className="App-logo"
            src={Logo}
            alt="Morningside Continuing Life"
          />
        </Box>
        <Box
          id="transparent"
          sx={{
            mx: "auto",
            width: 250,
            p: 2,
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#101010" : "grey.50",
            color: (theme) =>
              theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.800" : "grey.300",
            borderRadius: 2,
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "700",
            marginRight: "0",
          }}
        >
          <Typography>
            Meal Points Available
            <Typography
              variant="h5"
              component="div"
              align="center"
              style={{ fontWeight: "800" }}
            >
              {mealBalance}
            </Typography>
          </Typography>
        </Box>
      </Toolbar>
    </div>
  );
}
